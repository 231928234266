import type { Country, ProductAdminRoute, ProductCategory, ProductForm, ProductType, Region } from '@metly/api/models/products/types';
import { atom } from 'jotai';

export const searchQueryAtom = atom('');

export type SearchFilters = {
	type: ProductType[];
	region: Region[];
	form: ProductForm[];
	category: ProductCategory[];
	country: Country[];
	administrationRoute: ProductAdminRoute[];
};

export const searchFiltersAtom = atom<SearchFilters>({
	type: [],
	region: [],
	form: [],
	category: [],
	country: [],
	administrationRoute: [],
});

export function hasActiveFilters(filters: SearchFilters): boolean {
	return Object.values(filters).some(filterArray => filterArray.length > 0);
}